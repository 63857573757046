<template>
    <div class="profile-steps-wrapper">
        <h1>Create new server profile</h1>
        <div class="profile-steps">
          <div v-bind:class="['profile-step', this.step > 0 ? 'done' : '']">Select game</div>
          <div v-bind:class="['profile-step', this.step > 1 ? 'done' : '']">Setup accounts</div>
          <div v-bind:class="['profile-step', this.step > 2 ? 'done' : '']">Configure options</div>
        </div>
        <router-link v-bind:to="this.$route.params.id == undefined ? '/' : '/' + this.$route.params.id + '/settings' " style="text-decoration: none;"><div class="cancel-setup">{{ this.$route.params.id == undefined ? 'Click here to cancel the setup' : 'Click here to cancel the edit'}} </div></router-link>
      </div>
</template>

<script>
export default {
    props: {
        step: Number
    }
}
</script>