<template>
  <div class="app">
    <Navbar v-bind:showProfile="false" />

    <div class="content">
      <StepIndicator v-bind:step="this.step" />

      <div v-if="this.step == 1" class="main-view">
        <div class="row-wrapper full">
          <div class="server-address-wrapper">
            <select
              type="text"
              class="server-address"
              v-model="game"
              v-bind:style="[this.game == '' ? {color:'#BABABA'}:{}]"
              v-on:change='updateProgress1'
            >
                <option value="" selected disabled hidden>Pick a game...</option>
                <option value="0">Lords Mobile</option>
            </select>
            <div class="server-address-info">
              <p>
                All game bots are included in a subscription, this means you can choose any game out of the options without any extra payments.
              </p>
            </div>
          </div>
        </div>

        <div class="row-wrapper full">
          <div class="next-step-wrapper">
            <div></div>
            <button class="button-primary next-step-button" v-bind:disabled="!canProgress1" v-on:click='step = 2; requestConfigs()'>Next</button>
          </div>
        </div>
      </div>

      <div v-if="this.step == 2" class="main-view">
        <div class="row-wrapper" style="display: flex; padding: 10px; flex-flow: wrap; width: 100%;">
            <AccountEntry v-for="config in availableConfigs" v-bind:key="config.bssid" 
              v-bind:name="config.name"
              v-bind:id="config.bssid" 
              v-bind:date="config.date"
            />   
        </div>

        <div class="row-wrapper full">
          <div class="next-step-wrapper">
            <div>
                <span class="explanation-span">These profiles are taken from your MEMu config. <a href="https://help.afkadvantage.com/memu-configuration" target="_blank">Click here</a> for a tutorial on setting up new profiles or <a v-on:click="requestConfigs()" href="#">click here</a> to refresh the displayed profiles.</span>
            </div>

            <button v-if="this.$route.params.id == undefined" class="button-primary next-step-button" v-bind:disabled="!canProgress2" v-on:click='step = 3'>
              Next
            </button>
            <button v-else class="button-primary next-step-button" v-bind:disabled="!canProgress2" v-on:click='saveSelectedAccounts()'>
              Save
            </button>
          </div>
        </div>
      </div>

      <div v-if="this.step == 3" class="main-view">
        <div class="row-wrapper full">
            <div class="profile-preset">
                <strong>Full</strong>
                <p>All modules are enabled. Each module can later be changed individually.</p>
            </div>
        </div>

        <div class="row-wrapper full">
          <div class="next-step-wrapper">
            <div style="width: 100%;">
                <input v-model="profileName" @change="updateProgress3" v-on:input='updateProgress3' placeholder="Profile name..." class="profile-name-input" type="text">
            </div>

            <button class="button-primary next-step-button" v-bind:disabled="!canProgress3" v-on:click='completeCreation'>
              Next
            </button>
          </div>
        </div>
      </div>

      <div v-if="this.step == 4" class="main-view">
        <div class="creating-profile-wrapper">
          <div class="loader"></div>
          <div>Creating profile, please wait...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/global/navbar/Navbar";
import StepIndicator from "../../components/profile/steps/Indicator";
import AccountEntry from '../../components/profile/accounts/AccountEntry';
import Toast from '../../scripts/toast'

import Api from '../../scripts/api';

export default {
  components: { Navbar, StepIndicator, AccountEntry },
  data() {
    return {
      step: this.$route.params.id == undefined ? 1 : 2,
      game: '',
      accountEntries: {},
      availableConfigs: [],
      preset: 1,
      profileName: '',
      canProgress1: false,
      canProgress2: false,
      canProgress3: false
    };
  },
  created: function() {
    if(this.$route.params.id != undefined)
      this.requestConfigs();
  },
  methods: {
      updateProgress1: function() {
        this.canProgress1 = this.game != '';
      },
      onAccountEntriesChanged: function(entryId, enabled) {
        this.accountEntries[entryId] = enabled;
        
        // Reset state and enable it only if we have at least one element selected.
        this.canProgress2 = false; 
        Object.values(this.accountEntries).forEach(entry => {
            if(entry) this.canProgress2 = true; // can progress if at least one is selected.
        });
      },
      updateProgress3: function() {
          this.canProgress3 = this.preset > 0 && this.profileName != '';
      },
      completeCreation: async function() {
          this.step = 4;

          var accountEntryData = [];
          Object.keys(this.accountEntries).forEach((x) => {
            if(this.accountEntries[x] === true) {
              this.availableConfigs.forEach((z) => {
                if(x == z.bssid) {
                  accountEntryData.push(z);
                }
              });
            } 
          })

          try {
            await Api.profilesCreate(this.profileName, parseInt(this.game), accountEntryData);
            this.$router.push('/')
          } catch (ex) {
            // TODO: error handling.
            Toast.error('Could not create Profile. Please try again, or if this issue persist please open a support ticket.')
            this.step = 3;
          }
      },

      saveSelectedAccounts: async function() {

        var accountEntryData = [];
          Object.keys(this.accountEntries).forEach((x) => {
            if(this.accountEntries[x] === true) {
              this.availableConfigs.forEach((z) => {
                if(x == z.bssid) {
                  accountEntryData.push(z);
                }
              });
            } 
          })

        await Api.profileSetAccounts(this.$route.params.id, accountEntryData);
        this.$router.push('/' + this.$route.params.id + '/settings')
      },

      requestConfigs: async function() {
        this.availableConfigs = [];
        try {
          var profiles = await Api.profileGetConfigs();
          this.availableConfigs = profiles.data;
        } catch (ex) {
          // TODO: error handling.
          Toast.error('Could not retrieve client\'s Configs. Make sure that the client application is running, and refresh the website.')
        }
      }
  }
};
</script>

<style scoped>
@import "../../assets/css/create-profile.css";
</style>