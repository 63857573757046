<template>
    <div class="memu-account" v-on:click="onClicked" v-bind:style="[selected ? {boxShadow: '0px 0px 6px 3px rgba(81, 161, 255, 0.45)', color: '#5d5d5d'} : {}]">
        <strong>{{this.name}}</strong>
        <p>Created on {{timeConverter(this.date.substr(0,10))}}</p>
    </div>
</template>

<script>
export default {
    props: {
        name:String,
        id:String,
        date:String
    },
    data() {
        return {
            selected: false
        }
    },
    methods: {
        onClicked: function() {
            this.selected = !this.selected;
            this.$parent.onAccountEntriesChanged(this.id, this.selected);
        },
        timeConverter: function(UNIX_timestamp){
            var a = new Date(UNIX_timestamp * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var time = date + ' ' + month + ' ' + year;
            return time;
        }
    }
}
</script>